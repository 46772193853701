<template>
  <div class="vm-district" style="width: 100%">
    <el-cascader
      v-model="internalValue"
      :placeholder="$l('common.select','请选择')"
      :options="options"
      style="width: 100%"></el-cascader>
  </div>
</template>

<script>
  import {mutations, store} from "@/store/store";

  export default {
    props: {
      areaCode: String,
    },
    data() {
      return {
        result: null,
      };
    },
    computed: {
      options() {
        return store.district;
      },
      internalValue: {
        get() {
          let areaCode = this.areaCode + "";
          if (!areaCode) {
            return null;
          }
          if (this.result && areaCode !== this.result[2]) {
            return this.findParent(this.options, areaCode);
          }
          return this.result || this.findParent(this.options, areaCode);
        },
        set(v) {
          this.result = v;
          this.$emit("update:areaCode", v[2]);
        },
      },
    },
    mounted() {
      this.getOptions();
    },
    methods: {
      findParent(data2, nodeId2) {
        let arrRes = [];
        let rev = (data, nodeId) => {
          for (let i = 0, length = data.length; i < length; i++) {
            let node = data[i];
            if (node.id === nodeId) {
              arrRes.unshift(node.id);
              rev(data2, node.parentId);
              break;
            } else {
              if (node.children) {
                rev(node.children, nodeId);
              }
            }
          }
          return arrRes;
        };
        arrRes = rev(data2, nodeId2);
        return arrRes;
      },
      getOptions() {
        mutations.getDistrict().then(() => {
          console.log("----获取地区成功----");
        });
      },
    },
  };
</script>

<style lang="scss">
</style>
