<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="选择电梯"
    class="small-padding"
    width="1600px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="elevator/archive/common/page">
      <template slot="adSearch">
        <div class="vm-search">
          <div class="vm-search">
            <vm-search label="楼盘名称">
              <el-input v-model.trim="filter.realEstateName" clearable></el-input>
            </vm-search>
            <vm-search label="楼宇名称">
              <el-input v-model.trim="filter.buildingName" clearable></el-input>
            </vm-search>
            <vm-search label="注册代码">
              <el-input v-model.trim="filter.regCode" clearable></el-input>
            </vm-search>
            <vm-search label="出厂编号">
              <el-input v-model.trim="filter.factoryCode" clearable></el-input>
            </vm-search>
            <vm-search label="电梯名称">
              <el-input v-model.trim="filter.name" clearable></el-input>
            </vm-search>
            <vm-search label="救援代码">
              <el-input v-model.trim="filter.rescueCode" clearable></el-input>
            </vm-search>
          </div>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘名称" align="center"></el-table-column>
      <el-table-column prop="buildingName" label="楼宇名称" align="center"></el-table-column>
      <el-table-column prop="elevatorName" label="电梯名称" align="center"></el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center"></el-table-column>
      <el-table-column prop="rescueCode" label="救援代码" align="center"></el-table-column>
      <el-table-column width="70" :label="$l('common.function','操作')" align="center">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>

  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        filter: {
          name: "",
          regCode: "",
          factoryCode: "",
          rescueCode: "",
          type: "",
          status: "",
          projectName: "",
          deviceNo: "",
          index:0,
        },
        senior:false,
      };
    },
    methods: {
      open(index) {
        this.filter={};
        this.index = index;
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.$emit("select", row,this.index);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
